
import agent, { masterServiceBaseURL } from '@/services/agent';
import { defineComponent } from 'vue';
import { RecoverPassword, RecoverPasswordResponse } from './RecoverPassword.model';
import authService from '@/services/authService';
import router from '@/router/router';
import preLoader from '@/services/preLoader';
import toasterService from '@/services/toasterService';
import { API_KEY } from './Constants';

let _recoverPasswordForm: RecoverPassword = {} as RecoverPassword;

export default defineComponent({
  created() {
    this.classAddRemove();
    agent.setBaseUrl(masterServiceBaseURL);
  },
  data() {
    return {
      form: _recoverPasswordForm,
      isRecoverPasswordLoader: false,
    }
  },
  mounted() {
    this.form = {} as RecoverPassword;
    preLoader.hide();
    if (location.search) {
      this.onValidateRecoveryKey();
    } else {
      router.push("/");
    }
  },
  methods: {
    async onValidateRecoveryKey() {
      const params = this.split(location.search);
      const response = await agent.postAnonymous<RecoverPasswordResponse>(API_KEY.POST_VALIDATE_RECOVERY_KEY, params);
      if (response && !response.result.isValid) {
        toasterService.error(response.result.message, 5000);
        router.push("/login");
      }
      else {
        this.form.recoveryKey = params.recoveryKey;
      }
    },
    async onRecoverPassword(event: any) {
      event.preventDefault();

      let post = { ...this.form };

      this.isRecoverPasswordLoader = true;

      const response = await agent.postAnonymous<RecoverPasswordResponse>(API_KEY.POST_RECOVER_PASSWORD, post);
      if(response && response.result){
        toasterService.success(this.$t('msgRecoverPasswordSuccessfully'));
        this.form = {} as RecoverPassword;
        this.$router.push("/home");
      }
      this.isRecoverPasswordLoader = false;
    },
    split(url: string) {
      let regex = /[?&]([^=#]+)=([^&#]*)/g;
      let params = [];
      let match;

      while ((match = regex.exec(url))) {

        const paramName = decodeURIComponent(match[1]);

        const paramValue = decodeURIComponent(match[2]);

        params.push({ paramName: paramName, value: paramValue });

      }
      let payload = {
        recoveryKey: params.find(x => x.paramName.indexOf("recoveryKey") > -1)!.value,
      }
      return payload;
    },
    RedirectTo() {
      const isExpired = authService.isExpired(authService.getToken());
      if (!isExpired) router.push("/");
    },
    classAddRemove() {
      var bodyElement = document.body;
      var sidbarminiClass = bodyElement.classList.contains("sidebar-mini");
      var layoutfixedClass = bodyElement.classList.contains("layout-fixed");
      var registerpageClass = bodyElement.classList.contains("register-page");
      if (sidbarminiClass) bodyElement.classList.remove('sidebar-mini');
      if (layoutfixedClass) bodyElement.classList.remove('layout-fixed');
      if (registerpageClass) bodyElement.classList.remove('register-page');
    }
  }
});
