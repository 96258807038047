import CompanyComponent from '@/components/poweroffice/features/Company/CompanyComponent.vue';
import { RouteRecordRaw } from 'vue-router';

const routePrefix = "/power/"

const powerOfficePageRoutes: RouteRecordRaw[] = 
[
    {
        path: `${routePrefix}company`,
        name: 'Company',
        component: CompanyComponent,
        meta: { requiresAuth: true }
    },
];

export default powerOfficePageRoutes;