
import { GridComponent as EjsGrid, ColumnsDirective as EColumns, ColumnDirective as EColumn, Resize, Toolbar, Search, CommandColumn, Page, Group, Sort }
    from '@syncfusion/ej2-vue-grids';
import { defineComponent } from 'vue';
import modal from '@/components/common/modalManage';
import staticMessage from '@/components/common/staticMessage';
import ConfirmComponent from '@/components/molecules/ConfirmComponent.vue';
import agent, { masterServiceBaseURL } from '@/services/agent';
import toasterService from '@/services/toasterService';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { Module, ModuleGetResponse, ModuleResponse, Property } from './Module.model';
import authService from '@/services/authService';

let _moduleData: Property = {} as Property;
let _moduleForm: Module = {} as Module;

export default defineComponent({
    components: {
        "ejs-grid": EjsGrid,
        "e-columns": EColumns,
        "e-column": EColumn,
        ConfirmComponent
    },
    provide: {
        grid: [Toolbar, Resize, Search, CommandColumn, Page, Sort, Group]
    },
    created() {
        agent.setBaseUrl(masterServiceBaseURL);
        this.gridLocalize();
    },
    data() {
        return {
            data: _moduleData,
            form: _moduleForm,
            isUpdate: false,
            isTableLoad: false,
            confirmTitle: "",
            confirmIsDelete: false,
            selectedState: null,
            searchOptions: { fields: ['Name', 'DisplayName'], operator: 'contains', ignoreCase: true },
            toolbar: ['Search'],
            pageSettings: {
                pageSizes: [10, 20, 50, 100],
                pageSize: 50,
                pageCount: 5
            },
            commands: [
                { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
            ],
            locale: ''
        }
    },
    mounted() {
        this.dataStateChange(this.initState());
    },
    methods: {
        initState(): any {
            let state = {
                skip: 0,
                take: this.pageSettings.pageSize
            };
            return state;
        },
        dataStateChange(state: any) {
            this.gridLocalize();
            this.loadModule(state);
        },
        async loadModule(state: any) {
            if (state.action) {
                delete state.action;
            }

            const gridModule:any = this.$refs.gridModule;
            if(gridModule) gridModule.hideSpinner();

            const response = await agent.post<ModuleGetResponse>(`v1/module/getmodule`, state);
            if (response && response.result) {
                this.data = response.result;
            }
        },
        onUpsert(event: any) {
            event.preventDefault();

            if (this.isUpdate) {
                this.onEdit();
            } else {
                this.onSave();
            }
        },
        onSave() {
            let post = { ...this.form };
            post.name = this.form.displayName;
            post.createdBy = authService.getUserEmail();

            agent.post<ModuleResponse>(`v1/module`, post).then((response) => {
                if (response.result) {
                    toasterService.success(this.$t("msgSave"));
                    this.loadModule(this.initState());
                    this.resetModule();
                    this.close();
                }
            }).catch(() => { });
        },
        onEdit() {
            let put = { ...this.form };
            put.modifiedBy = authService.getUserEmail();

            agent.put<ModuleResponse>(`v1/module`, put).then((response) => {
                if (response.result) {
                    toasterService.success(this.$t("msgUpdate"));
                    this.loadModule(this.initState());
                    this.resetModule();
                    this.close();
                }
            }).catch(() => { });
        },
        onDelete() {
            let put = { ...this.form };

            agent.delete<ModuleResponse>(`v1/module/${put.id}`).then((response) => {
                modal.Close(staticMessage.modalConfirm);
                if (response.result) {
                    toasterService.success(staticMessage.delete);
                    this.loadModule(this.initState());
                    this.resetModule();
                }
            }).catch(() => { });
        },
        resetModule() {
            this.form = {} as Module;
            this.isUpdate = false;
        },
        commandConfirm() {
            if (this.confirmIsDelete) {
                this.onDelete();
                this.confirmIsDelete = false;
            }
        },
        commandClick: function (args: { commandColumn: any, rowData: Module }) {
            this.isUpdate = false;
            if (args) {
                switch (args.commandColumn.type) {
                    case 'Edit':
                        this.form = args.rowData;
                        this.isUpdate = true;
                        modal.Open('moduleModal');
                        break;
                    default: break;
                }
            }
        },
        gridLocalize() {
            this.locale = 'en-grid';
            if (this.$i18n.locale == 'se') {
                setTimeout(() => {
                    import(`@/assets/sv.json`).then(module => {
                        const localText = module.default;
                        this.locale = 'sv';
                        setCulture('sv');
                        L10n.load(localText);
                    });
                });
            }
        },
        open() {
            this.form = {} as Module;
            this.isUpdate = false;
            modal.Open('moduleModal');
        },
        close() {
            this.form = {} as Module;
            modal.Close('moduleModal');
        },
    }
});
