
import { defineComponent, } from 'vue';
import { DropDownListComponent } from '@syncfusion/ej2-vue-dropdowns';
import { DbConnectionResponse, RegisterResponse } from './Register.model';
import agent, { masterServiceBaseURL } from '@/services/agent';
import toasterService from '@/services/toasterService';
import _ from 'lodash';
import authService from '@/services/authService';
import router from '@/router/router';
import { BooleanResponse, Module, ModuleGetResponse, SchemaResponse } from '../Module/Module.model';
import { Subscription, SubscriptionResponse } from '../Subscription/Subscription.model';
import modal from '@/components/common/modalManage';
import emitter from '@/services/mitt';
import { API_KEY } from './Constants';

let _registerForm: Subscription = {} as Subscription;
let _databases: string[] = [];
let _moduleData: Module[] = [];

export default defineComponent({
    props: {
        IsSubscription: Boolean,
        IsUpdate: Boolean,
    },
    components: {
        "ejs-dropdownlist": DropDownListComponent
    },
    created() {
        this.classAddRemove();
        agent.setBaseUrl(masterServiceBaseURL);
    },
    data() {
        return {
            listDatabases: _databases,
            form: _registerForm,
            isConnectLoader: false,
            isRegisterLoader: false,
            isModuleLoader: false,
            listModule: _moduleData,
            fieldsModule: { text: 'name', value: 'id' },
            dbSchemaList: [] as Array<string>,
            isOrganizationNameUnique: true,
            isOrganizationNameValid: true,
            isOrganizationNameGood: false
        }
    },
    computed: {
        isTestConnectionSucceeded() {
            return this.listDatabases?.length > 0;
        },
        serverInformationProvided() {
            if (!this.form.useExternalDB)
                return true;

            return Boolean(this.form.sqlServerName && this.form.sqlServerUserName && this.form.sqlServerPassword && this.form.databaseName);
        }
    },
    mounted() {

        this.debouncedGetSchemaList = _.debounce(this.loadSchema, 800);
        this.debouncedCheckIfOrganizationNameGood = _.debounce(this.checkIfOrganizationNameGood, 800);
        this.resetRegister();
        this.RedirectTo();
        this.loadModule();

        emitter.on('setForm', form => {
            const getForm = form as Subscription;
            this.form = getForm;
            this.loadModule();
        });
    },
    methods: {
        debouncedGetSchemaList() { },
        debouncedCheckIfOrganizationNameGood() { },
        onCheckDbConnection() {
            let params = {
                sqlServerName: this.form.sqlServerName,
                sqlServerUserName: this.form.sqlServerUserName,
                sqlServerPassword: this.form.sqlServerPassword
            };

            if (_.isEmpty(params.sqlServerName)) {
                toasterService.warn(this.$t('msgGiveServer'));
                return;
            }

            if (_.isEmpty(params.sqlServerUserName)) {
                toasterService.warn(this.$t('msgGiveDBUserName'));
                return;
            }

            if (_.isEmpty(params.sqlServerPassword)) {
                toasterService.warn(this.$t('msgGiveDBUserPassword'));
                return;
            }

            if (!this.IsSubscription) {
                this.isConnectLoader = true;
            }

            this.listDatabases = [];
            agent.getAnonymous<DbConnectionResponse>(API_KEY.GET_DB_CONNECTION, params).then((response) => {
                if (response.result) {
                    this.listDatabases = response.result;
                    this.isConnectLoader = false;
                }
            }).catch(() => {
                this.isConnectLoader = false;
            });
        },
        onSave(event: any) {
            event.preventDefault();

            if (this.IsSubscription && this.IsUpdate) {

                const modules = this.form.moduleCheckBox
                    .filter(m => m.isChecked)
                    .map(m =>
                    ({
                        id: m.id,
                        selected: m.isChecked,
                        schemaName: m.schemaName,
                        name: m.schemaName
                    }));

                const put: Subscription = {
                    ...this.form,
                    modules: modules,
                    modifiedBy: authService.getUserEmail()
                };

                console.log("put", put);

                agent.put<SubscriptionResponse>(API_KEY.PUT, put).then((response) => {
                    if (response.result) {
                        this.isRegisterLoader = false;
                        emitter.emit('changeSubscriptions', {})
                    }
                }).catch(() => {
                    this.isRegisterLoader = false;
                });
            } else {
                let post = { ...this.form };

                if(this.isOrganizationNameGood === false) {
                    toasterService.warn(this.$t("msgOrganizationNameNotGood"));
                    return;
                }

                if (post.password != post.confirmPassword) {
                    toasterService.warn(this.$t("msgPasswordConfirmPassword"));
                    return;
                }

                if (!post.moduleCheckBox.some(i => i.isChecked === true)) {
                    toasterService.warn(this.$t("msgSelectModuleAtLeast"));
                    return;
                }

                if (post.moduleCheckBox.some(m => (m.isChecked && m.isDuplicate) || (m.isChecked && !m.isValid))) {
                    toasterService.warn(this.$t('msgModuleError'));
                    return;
                }

                if (post.useExternalDB) {
                    if (_.isEmpty(post.databaseName)) {
                        toasterService.warn(this.$t("msgSelectDatabase"));
                        return;
                    }
                }

                // post.modules = post.moduleCheckBox.filter(i => i.isChecked == true).map(m => m.id);
                post.modules = post.moduleCheckBox
                    .filter(m => m.isChecked)
                    .map(m =>
                    ({
                        id: m.id,
                        selected: m.isChecked,
                        schemaName: m.schemaName,
                        name: m.schemaName
                    }));

                this.isRegisterLoader = true;
                agent.postAnonymous<RegisterResponse>(API_KEY.REGISTER, post).then((response) => {
                    if (response.result) {
                        if (this.IsSubscription) {
                            this.isRegisterLoader = false;
                            document.getElementById('subsWiseRegister')!.dispatchEvent(new Event('change'));
                        } else {
                            toasterService.success('Subscription created!, Please try login');
                            this.onRedirectSuccess();
                        }
                    }
                }).catch(() => {
                    this.isRegisterLoader = false;
                });
            }
        },
        onLoadRegisterAfterSubscribe() {
            this.resetRegister();
            this.loadModule();
        },
        async loadModule() {
            this.form.moduleCheckBox = [];
            const self = this;

            this.isModuleLoader = true;
            const response = await agent.postAnonymous<ModuleGetResponse>(API_KEY.GET_MODULE, {});
            if (response && response.result) {
                this.listModule = response.result.result;
                console.log({ listModule: this.listModule });
                _.map(this.listModule, item => {
                    const module = this.form.modules.find(i => i.id == item.id);
                    this.form.moduleCheckBox.push({
                        id: Number(item.id),
                        isChecked: this.form.modules.some(i => i.id == item.id),
                        schemaName: module ? module.schemaName.toLowerCase() : item.schemaName.toLowerCase(),
                        isDuplicate: false,
                        isValid: true
                    });
                });

                // setting watchers on schema name changes
                this.form.moduleCheckBox.forEach(item => {
                    self.$watch(
                        () => item.schemaName,
                        () => self.debouncedGetSchemaList()
                    )
                });

                // add same watcher for Organization Name
                self.$watch(
                    () => self.form.organizationName,
                    () => self.debouncedCheckIfOrganizationNameGood()
                );

                
                // add same watcher for Organization Name
                self.$watch(
                    () => self.form.databaseName,
                    () => self.debouncedGetSchemaList()
                );
            }
            this.isModuleLoader = false;
        },
        close() {
            modal.Close('subscriptionModal');
        },
        onRedirectSuccess() {
            this.isRegisterLoader = false;
            this.resetRegister();
            this.$router.push("/login");
        },
        resetRegister() {
            this.form = {} as Subscription;
            this.form.databaseName = "";
            this.form.modules = [];
            this.form.moduleCheckBox = [];
        },
        RedirectTo() {
            if (!this.IsSubscription) {
                const isExpired = authService.isExpired(authService.getToken());
                if (!isExpired) router.push("/");
            }
        },
        classAddRemove() {
            if (!this.IsSubscription) {
                var bodyElement = document.body;
                var sidbarminiClass = bodyElement.classList.contains("sidebar-mini");
                var layoutfixedClass = bodyElement.classList.contains("layout-fixed");
                var registerpageClass = bodyElement.classList.contains("register-page");
                if (sidbarminiClass) bodyElement.classList.remove('sidebar-mini');
                if (layoutfixedClass) bodyElement.classList.remove('layout-fixed');
                if (registerpageClass) bodyElement.classList.remove('register-page');
            }
        },
        checkIfOrganizationNameGood() {
            if (this.form.organizationName) {
                if (this.isValidOrganizationName(this.form.organizationName)) {
                    this.isOrganizationNameValid = true;
                    agent.getAnonymous<BooleanResponse>(API_KEY.CHECK_ORGANIZATION_EXISTANCE, { organizationName: this.form.organizationName }).then(response => {
                        this.isOrganizationNameUnique = response.result == false;
                        this.isOrganizationNameGood = this.isOrganizationNameUnique;
                    });
                } else {
                    this.isOrganizationNameValid = false;
                    this.isOrganizationNameGood = this.isOrganizationNameValid;
                }
            }
        },
        loadSchema() {
            const selectedCheckboxed = this.form.moduleCheckBox.filter(m => m.isChecked);

            for (let module of selectedCheckboxed) {
                if (module.schemaName) {
                    module.isValid = this.isValidSchemaName(module.schemaName);
                    console.log("module.isValid", module.isValid);
                }
            }

            if (selectedCheckboxed.some(m => m.isValid === false)) {
                return;
            }

            let selectedModules = this.form.moduleCheckBox.filter(m => m.isChecked);

            if(this.form.useExternalDB) {
                const self = this;
                let params = {
                    sqlServerName: this.form.sqlServerName,
                    sqlServerUserName: this.form.sqlServerUserName,
                    sqlServerPassword: this.form.sqlServerPassword,
                    useInternalDatabase: !this.form.useExternalDB,
                    sqlServerCatalogName: this.form.databaseName,
                    organizationName: this.form.organizationName,
                };

                agent.getAnonymous<SchemaResponse>(API_KEY.GET_SCHEMA, params, false).then(response => {
                    self.dbSchemaList = response.result;                
                    for (let module of this.form.moduleCheckBox) {
                        module.isDuplicate = self.dbSchemaList.includes(module.schemaName) || selectedModules.filter(m => m.schemaName === module.schemaName).length > 1;
                    }
                });
            }
            else{
                for (let module of this.form.moduleCheckBox) {
                    module.isDuplicate = selectedModules.filter(m => m.schemaName === module.schemaName).length > 1;
                }
                return;
            }            
        },
        isValidOrganizationName(name: string) {
            const regex = /^[a-zA-Z][a-zA-Z0-9_ ]*$/;
            return regex.test(name);
        },
        isValidSchemaName(name: string) {
            const regex = /^[a-zA-Z][a-zA-Z0-9_]*$/;
            return regex.test(name);
        }
    },
});
