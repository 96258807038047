import { RouteRecordRaw } from "vue-router";

const resultSheetRoutes:RouteRecordRaw[] = [
    {
		path: "/account-mapping",
		name: "Account Mapping",
		component: ()=>import("@/components/resultsheet/features/AccountMapping/AccountMappingComponent.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/account-group",
		name: "Account Group",
		component: ()=>import("@/components/resultsheet/features/AccountMapping/AccountGroupComponent.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/result-sheet",
		name: "Result Sheet",
		component: ()=>import("@/components/resultsheet/features/ResultSheetDesign/ResultSheetDesign.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/balance-sheet",
		name: "Balance Sheet",
		component: ()=>import("@/components/resultsheet/features/BalanceSheetDesign/BalanceSheetDesign.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/balance-sheet-mapping",
		name: "Balance Sheet Mapping",
		component: ()=>import("@/components/resultsheet/features/BalanceSheetMapping/BalanceSheetMappingComponent.vue"),
		meta: { requiresAuth: true },
	},
    {
		path: "/permission-scope",
		name: "Permission scope",
		component: () => import("@/components/resultsheet/features/PermissionScope/PermissionScopeComponent.vue"),
		meta: { requiresAuth: true },
	},
    {
		path: "/rs-cost-center",
		name: "Result Sheet Cost Center",
		component: () => import("@/components/resultsheet/features/CostCenter/CostCenterComponent.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/rs-cost-center-permission",
		name: "Result Sheet Cost Center Permission",
		component: () => import("@/components/resultsheet/features/CostCenterPermission/CostCenterPermissionComponent.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/rs-account",
		name: "Result Sheet Account",
		component: () => import("@/components/resultsheet/features/Account/AccountComponent.vue"),
		meta: { requiresAuth: true },
	},
    {
		path: "/rs-project",
		name: "Result Sheet Project",
		component: () => import("@/components/resultsheet/features/Project/ProjectComponent.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/rs-company",
		name: "Result Sheet Company",
		component: () => import("@/components/resultsheet/features/Company/CompanyComponent.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/rs-customer",
		name: "Result Sheet Customer",
		component: () => import("@/components/resultsheet/features/Customer/CustomersComponent.vue"),
		meta: { requiresAuth: true },
	},
    {
        path:"/budget-forecast",
        name:"Budget Forecast",
        component:()=>import("@/components/resultsheet/features/BudgetForecast/ForecastComponent.vue"),
        meta:{requiresAuth:true}
    },
	{
        path:"/rs-employee",
        name:"Result sheet Employee",
        component:()=>import("@/components/resultsheet/features/Employee/EmployeeComponent.vue"),
        meta:{requiresAuth:true}
    },
	{
        path:"/rs-financial-year",
        name:"Result sheet Financial Year",
        component:()=>import("@/components/resultsheet/features/FinancialYear/FinancialYearComponent.vue"),
        meta:{requiresAuth:true}
    }
];

export default resultSheetRoutes;