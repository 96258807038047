import modal from '@/components/common/modalManage';
import staticMessage from '@/components/common/staticMessage';
import ConfirmComponent from '@/components/molecules/ConfirmComponent.vue';
import agent, { powerOfficeServiceBaseURL } from '@/services/agent';
import toasterService from '@/services/toasterService';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { CommandColumn, ColumnDirective as EColumn, ColumnsDirective as EColumns, GridComponent as EjsGrid, Group, Page, Resize, Search, Sort, Toolbar } from '@syncfusion/ej2-vue-grids';
import { defineComponent } from 'vue';
import { CompanyInfo, CompanyResponse } from './Company.model';
import { API_ENDPOINTS } from './Constants';

export default defineComponent({
    components: {
        'ejs-grid': EjsGrid,
        'e-columns': EColumns,
        'e-column': EColumn,
        ConfirmComponent,
    },
    provide: {
        grid: [Toolbar, Resize, Search, CommandColumn, Page, Sort, Group]
    },
    data() {
        return {
            data: [] as CompanyInfo[],
            form: {} as CompanyInfo,
            isUpdate: false,
            locale: '',
            searchOptions: { fields: ['name'], operator: 'contains', ignoreCase: true },
            toolbar: ['Search'],
            pageSettings: { pageSizes: [10, 20, 50, 100], pageSize: 50, pageCount: 5 },
            commands: [{ type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } }],
        };
    },
    created() {
        agent.setBaseUrl(powerOfficeServiceBaseURL);
        this.initializeLocale();
    },
    mounted() {
        this.loadCompany();
    },
    methods: {
        async loadCompany() {
            const gridModule:any = this.$refs.gridModule;
            if(gridModule) gridModule.hideSpinner();
            const response  = await agent.get<CompanyResponse>(API_ENDPOINTS.GET) || {};
                this.data = response.result.data;
        },

        async upsertCompany(event:any) {
            event.preventDefault();
            const apiEndpoint = this.isUpdate ? API_ENDPOINTS.PUT : API_ENDPOINTS.POST;
            const method = this.isUpdate ? agent.put<CompanyResponse> : agent.post<CompanyResponse>;
            const  result  = await method(apiEndpoint, this.form) || {};
                await this.loadCompany();
                toasterService.success(staticMessage.save);
                this.resetPage();
                this.closeModal();
            
        },

        initializeLocale() {
            this.locale = this.$i18n.locale === 'se' ? 'sv' : 'en-grid';
            if (this.locale === 'sv') {
                import('@/assets/sv.json').then(module => {
                    L10n.load(module.default);
                    setCulture('sv');
                });
            }
        },
        commandClick: function (args: { commandColumn: any, rowData: any }) {
            this.isUpdate = false;
            const app = this;
            if (args) {
                switch (args.commandColumn.type) {
                    case 'Edit':
                        this.form = args.rowData;
                        this.isUpdate = true;
                        modal.Open('PageModal');
                        break;
                    default: break;
                }
            }
        },

        openModal() {
            this.resetPage();
            modal.Open('PageModal');
        },

        closeModal() {
            this.resetPage();
            modal.Close('PageModal');
        },

        resetPage() {
            this.form =  {
                id : 0,
                isActive:false
            } as CompanyInfo;
            this.isUpdate = false;
        }
    }
});
