import { defineStore } from "pinia";
import { DataSyncConfigurationBySubscriptionModel, GetDataSyncConfigurationBySubscriptionResponse } from "@/components/master/features/DataSyncConfiguration/DataSyncConfiguration.model";
import axios from "axios";
import { masterServiceBaseURL } from "@/services/agent";
import authService from "@/services/authService";

export const useDataSyncConfigurationStore = defineStore({
    id: "DataSyncConfiguration",
    state: () => ({
        dataSyncConfigurationBySubscription: {} as DataSyncConfigurationBySubscriptionModel,
    }),
    actions: {
        async fetchDataSyncConfigurationBySubscription(subscriptionId:string){
            return await axios
            .get<GetDataSyncConfigurationBySubscriptionResponse>
            (`${masterServiceBaseURL}v1/DataSyncConfiguration/GetDataSyncConfiguration/${subscriptionId}`,{
                headers:{ Authorization: `Bearer ${authService.getToken()}` }
            })
            .then(response=>response && response.data);
        },
        setDataSyncConfigurationBySubscription(dataSyncConfigurationBySubscription: DataSyncConfigurationBySubscriptionModel) {
            this.dataSyncConfigurationBySubscription = dataSyncConfigurationBySubscription;
        },
    },
});