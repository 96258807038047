
import { GridComponent as EjsGrid, ColumnsDirective as EColumns, ColumnDirective as EColumn, Resize, Toolbar, Search, CommandColumn, Page, Group, Sort }
    from '@syncfusion/ej2-vue-grids';
import { defineComponent } from 'vue';
import modal from '@/components/common/modalManage';
import staticMessage from '@/components/common/staticMessage';
import ConfirmComponent from '@/components/molecules/ConfirmComponent.vue';
import agent, { fortnoxServiceBaseURL, masterServiceBaseURL } from '@/services/agent';
import toasterService from '@/services/toasterService';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { DatePickerComponent } from '@syncfusion/ej2-vue-calendars';
import { Company, CompanyGetResponse, CompanyResponse, Property, SyncResponse } from './Company.model';
import preLoader from '@/services/preLoader';
import { hasFortnox } from '@/shared/app.util';

let _companyData: Property = {} as Property;
let _companyForm: Company = {} as Company;

export default defineComponent({
    components: {
        "ejs-grid": EjsGrid,
        "e-columns": EColumns,
        "e-column": EColumn,
        ConfirmComponent,
        DatePickerComponent
    },
    provide: {
        grid: [Toolbar, Resize, Search, CommandColumn, Page, Sort, Group]
    },
    created() {
        agent.setBaseUrl(hasFortnox() ? fortnoxServiceBaseURL : masterServiceBaseURL);

        this.gridLocalize();
    },
    data() {
        return {
            data: _companyData,
            form: _companyForm,
            isUpdate: false,
            isTableLoad: false,
            confirmTitle: "",
            confirmIsDelete: false,
            selectedState: null,
            searchOptions: { fields: ['Name', 'CreationTime', 'RefreshTokenExpireDate', 'LastSyncDate'], operator: 'contains', ignoreCase: true },
            toolbar: ['Search'],
            pageSettings: {
                pageSizes: [10, 20, 50, 100],
                pageSize: 50,
                pageCount: 5,
                skip:0,
                take:50
            },
            commands: [
                { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
                { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
                { type: 'Force Fortnox Sync', buttonOption: { cssClass: 'e-flat force-sync', iconCss: 'e-repeat e-icons' } },
                { type: 'Fortnox Activation', buttonOption: { cssClass: 'e-flat', iconCss: 'e-plus e-icons' } },
            ],
            locale: '',
            forceSync: {
                date: null,
                format: 'yyyy-MM-dd',
                today: new Date(),
                companyId: 0,
                type: 'full'
            }
        }
    },
    mounted() {
        preLoader.hide();
        if (location.search) {
            let params = this.split(location.search);
            agent.get<CompanyGetResponse>(`v1/fortnoxactivation/activatefortnox`, params).then((response) => {
                if (response.result) {
                    this.$router.replace('/company');
                    toasterService.success(this.$t("msgSave"));
                    this.loadCompany(this.initState(this.pageSettings.skip,this.pageSettings.take));
                }
            });
        } else {
            this.dataStateChange(this.initState(this.pageSettings.skip,this.pageSettings.take));
        }
    },
    methods: {
        initState(skip:number=0,take:number): any {
            let state = {
                skip: skip,
                take: take
            };
            return state;
        },
        split(url: string) {
            let regex = /[?&]([^=#]+)=([^&#]*)/g;
            let params = [];
            let match;

            while ((match = regex.exec(url))) {

                const paramName = decodeURIComponent(match[1]);

                const paramValue = decodeURIComponent(match[2]);

                params.push({ paramName: paramName, value: paramValue });

            }

            let payload = {
                sid: params.find(x => x.paramName.indexOf("sid") > -1)!.value,
                code: params.find(x => x.paramName.indexOf("code") > -1)!.value,
                state: params.find(x => x.paramName.indexOf("state") > -1)!.value
            }

            return payload;
        },
        dataStateChange(state: any) {
            this.pageSettings.skip = state.skip;
            this.pageSettings.take = state.take;
            this.gridLocalize();
            this.loadCompany(state);
        },
        loadCompany(state: any) {
            if (state.action) {
                delete state.action;
            }

            const gridCompany:any = this.$refs.gridCompany;
            if(gridCompany){
                gridCompany.hideSpinner();
            }
            agent.post<CompanyGetResponse>(`v1/company/getcompany`, { ...state }).then((response) => {
                if (response.result) {
                    this.data = response.result;
                }
            }).catch(() => { });
        },
        onUpsert(event: any) {
            event.preventDefault();

            if (this.isUpdate) {
                this.onEdit();
            } else {
                this.onSave();
            }
        },
        onSave() {
            let post = { ...this.form };

            agent.post<CompanyResponse>(`v1/company`, post).then((response) => {
                if (response.result) {
                    toasterService.success(this.$t("msgSave"));
                    this.loadCompany(this.initState(this.pageSettings.skip,this.pageSettings.take));
                    this.resetCompany();
                    this.close();
                }
            }).catch(() => { });
        },
        onEdit() {
            let put = { ...this.form };

            agent.put<CompanyResponse>(`v1/company`, put).then((response) => {
                if (response.result) {
                    toasterService.success(this.$t("msgUpdate"));
                    this.loadCompany(this.initState(this.pageSettings.skip,this.pageSettings.take));
                    this.resetCompany();
                    this.close();
                }
            }).catch(() => { });
        },
        onDelete() {
            let put = { ...this.form };

            agent.delete<CompanyResponse>(`v1/company/${put.id}`, {}).then((response) => {
                modal.Close(staticMessage.modalConfirm);
                if (response.result) {
                    toasterService.success(staticMessage.delete);
                    this.loadCompany(this.initState(this.pageSettings.skip,this.pageSettings.take));
                    this.resetCompany();
                }
            }).catch(() => { });
        },
        resetCompany() {
            this.form = {} as Company;
            this.isUpdate = false;
        },
        gridLocalize() {
            this.locale = 'en-grid';
            if (this.$i18n.locale == 'se') {
                setTimeout(() => {
                    import(`@/assets/sv.json`).then(module => {
                        const localText = module.default;
                        this.locale = 'sv';
                        setCulture('sv');
                        L10n.load(localText);
                    });
                });
            }
        },
        commandConfirm() {
            if (this.confirmIsDelete) {
                this.onDelete();
                this.confirmIsDelete = false;
            }
        },
        commandClick: function (args: { commandColumn: any, rowData: Company }) {
            this.isUpdate = false;
            if (args) {
                switch (args.commandColumn.type) {
                    case 'Delete':
                        this.confirmTitle = this.$t("msgSureAllDataWithCompany");
                        this.form.id = args.rowData.id;
                        this.confirmIsDelete = true;
                        modal.Open(staticMessage.modalConfirm);
                        break;
                    case 'Edit':
                        this.form = args.rowData;
                        this.isUpdate = true;
                        modal.Open('companyModal');
                        break;
                    case 'Force Fortnox Sync':
                        this.forceSync.companyId = args.rowData.id;
                        modal.Open('force-sync-modal');
                        break;
                    case 'Fortnox Activation':
                        agent.get<SyncResponse>(`v1/FortnoxActivation/GetFortnoxActivationToken/${args.rowData.id}`).then((response) => {
                            if (response.result) {
                                window.location.href = response.result.data;
                                this.$router.push({ path: this.$route.path, query: { sid: 'sid' } })
                            }
                        }).catch(() => { });
                        break;
                    default: break;
                }
            }
        },
        forceSyncByDate() {
            const params = {
                companyId: this.forceSync.companyId,
                forceSyncDate: this.forceSync.type === 'full' ? null : this.forceSync.date
            };
            preLoader.show();
            agent.get<SyncResponse>(`v1/Company/FortnoxSync`, params).then((response) => {
                if (response.result) {
                    modal.Close('force-sync-modal');
                    toasterService.success(response.result.message);
                    preLoader.hide();
                }
            }).catch(() => {
                preLoader.hide();
                modal.Close('force-sync-modal');
            });
        },
        open() {
            this.form = {} as Company;
            this.isUpdate = false;
            modal.Open('companyModal');
        },
        close() {
            this.form = {} as Company;
            modal.Close('companyModal');
        },
        closeForceSyncModal() {
            modal.Close('force-sync-modal');
        },
        filterCommandsBasedOnModule() {
            if (hasFortnox()) {
                return this.commands;
            }
            return this.commands.filter(c => ['Edit', 'Delete'].includes(c.type));
        }
    },
});
