
import agent, { masterServiceBaseURL } from '@/services/agent';
import { defineComponent } from 'vue';
import { ChangePassword, ChangePasswordResponse } from './ChangePassword.model';
import toasterService from '@/services/toasterService';
import modal from '@/components/common/modalManage';
import authService from '@/services/authService';

let _changePasswordForm: ChangePassword = {} as ChangePassword;

export default defineComponent({
  created() {
    agent.setBaseUrl(masterServiceBaseURL);
  },
  data() {
    return {
      form: _changePasswordForm,
      isChangePasswordLoader: false,
    }
  },
  mounted() {
    this.form = {} as ChangePassword;
  },
  methods: {
    onChangePassword(event: any) {
      event.preventDefault();

      let post = { ...this.form };

      post.email = authService.getUserEmail()

      agent.post<ChangePasswordResponse>(`v1/account/changepassword`, post).then((response) => {
        if (response.result) {
          this.form = {} as ChangePassword;
          toasterService.success(this.$t('Password changed successfully!'));
          this.close();
        }
      }).catch(() => { });
    },
    close() {
      this.form = {} as ChangePassword;
      modal.Close('changePasswordModal');
    },
  }
});
