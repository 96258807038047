
import { GridComponent as EjsGrid, ColumnsDirective as EColumns, ColumnDirective as EColumn, Resize, Toolbar, Search, CommandColumn, Page, Group, Sort }
    from '@syncfusion/ej2-vue-grids';
import { defineComponent } from 'vue';
import agent, { fortnoxServiceBaseURL, masterServiceBaseURL } from '@/services/agent';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { SyncLog, SyncLogGetResponse, Property, SyncLogDetailsGetResponse, PropertySyncLogDetails } from './SyncLog.model';
import modal from '@/components/common/modalManage';
import axios from 'axios';
import authService from '@/services/authService';
import { Module } from '../Module/Module.model';
import preLoader from '@/services/preLoader';
import SubscriptionDropdown from '@/components/common/SubscriptionDropdown.vue';
import { useUserStore } from '@/store/useUserStore';
import { CommonResponse } from '@/components/common/model';
import VueSelect from '@/components/common/VueSelect.vue';
import { Company } from '@/components/master/features/Company/Company.model';
import EndpointProvider from './EndpointProvider';

let _syncLogData: Property = {} as Property;
let _syncLogDetailsData: PropertySyncLogDetails = {} as PropertySyncLogDetails;
let _syncLogForm: SyncLog = {} as SyncLog;
let _moduleList: Module[] = [];
const _endpointSelector = new EndpointProvider();
//let _companyList: Company[] = [];

export default defineComponent({
    setup() {
        const userStore = useUserStore();
        return { userStore };
    },
    components: {
        "ejs-grid": EjsGrid,
        "e-columns": EColumns,
        "e-column": EColumn,
        SubscriptionDropdown,
        VueSelect
    },
    provide: {
        grid: [Toolbar, Resize, Search, CommandColumn, Page, Sort, Group]
    },
    created() {
        agent.setBaseUrl(fortnoxServiceBaseURL);

        this.gridLocalize();
    },
    data() {
        return {
            moduleList: _moduleList,
            data: _syncLogData,
            form: _syncLogForm,
            moduleId: 0,
            isUpdate: false,
            isTableLoad: false,
            confirmTitle: "",
            confirmIsDelete: false,
            selectedState: null,
            companyId: 0,
            companies: [] as Company[],
            subscriptionId: '',
            details: {
                syncLogMasterId: 0,
                data: _syncLogDetailsData,
                searchOptions: { fields: [
                    //'Id',
                    //'LogId',
                    //'Entity',
                    'StartedAt',
                    'CompletedAt',
                    //'RunStatusId',
                    'HasError',
                    'ErrorMessage',
                    //'RowSynced',
                ], operator: 'contains', ignoreCase: true },
                toolbar: ['Search'],
                pageSettings: {
                    pageSizes: [10, 20, 50, 100],
                    pageSize: 20,
                    pageCount: 5,
                    skip:0,
                    take:50
                },
            },

            searchOptions: { fields: [
                'Id',
                'CompanyId',
                'RequestedAt',
                'RequestedBy',
                'StartedAt',
                'CompletedAt',
                //'RunStatusId',
                'HasError',
                'ErrorMessage',
                'IsFullSync',
                'JobRunId',
                'TryCount',
                'ForceSyncDate',
            ], operator: 'contains', ignoreCase: true },
            toolbar: ['Search'],
            pageSettings: {
                pageSizes: [10, 20, 50, 100],
                pageSize: 50,
                pageCount: 5,
                skip:0,
                take:50
            },
            locale: '',
            gridLocal: {
                'se-grid': {
                    'grid': {
                        'EmptyRecord': 'Inga poster visas', 'Search': 'Sök',
                        'GroupDropArea': 'Dra en kolumnrubrik hit för att gruppera din kolumn',
                        'UnGroup': 'Klicka här för att avgruppera',
                        'EmptyDataPageError': 'DataPage får inte vara tom vid den första laddningen eftersom kolumner från dataPage finns i AutoGenerate Column Grid',
                        'Item': 'Artikel',
                        'Items': 'Artikel'
                    },
                    'pager': {
                        'currentPageInfo': '{0} av {1} sidor',
                        'totalItemsInfo': '({0} inlägg)',
                        'firstPageTooltip': 'Till första sidan',
                        'lastPageTooltip': 'Till sista sidan',
                        'nextPageTooltip': 'Till nästa sida',
                        'previousPageTooltip': 'Tillbaka till sista sidan',
                        'nextPagerTooltip': 'Till nästa personsökare',
                        'previousPagerTooltip': 'Gå till föregående personsökare',
                        'pagerDropDown': 'Objekt per sida'
                    }
                }
            }
        }
    },
    mounted() {
        this.dataStateChange(this.initState(this.pageSettings.skip,this.pageSettings.take));
        if(!this.userStore.userInfo.isHost) {
            this.loadModule();
        }
    },
    methods: {
        initState(skip:number=0,take:number): any {
            let state = {
                skip: skip,
                take: take
            };
            return state;
        },
        initStateDetails(): any {
            let state = {
                skip: 0,
                take: this.details.pageSettings.pageSize
            };
            return state;
        },
        actionColumnClick(rowData: SyncLog) {
            this.details.syncLogMasterId = rowData.id;
            this.open();
            this.loadSyncLogDetails(this.initStateDetails());
        },
        dataStateChange(state: any) {
            this.pageSettings.skip = state.skip;    
            this.pageSettings.take = state.take;
            this.gridLocalize();
            //this.loadSyncLog(state);
        },
        dataStateChangeDetails(state: any) {
            this.gridLocalize();
            this.loadSyncLogDetails(state);
        },
        async loadSyncLog(state: any) {
            if (state.action) {
                delete state.action;
            }
            const { isHost, subscriptionId } = this.userStore.userInfo;
            const subParams = {
                subscriptionId: isHost ? this.subscriptionId : subscriptionId ,
                moduleId: this.moduleId,
                companyId: this.companyId
            };

            const gridSyncLog:any = this.$refs.gridSyncLog;
            if(gridSyncLog) gridSyncLog.hideSpinner();

            const syncLogMasterUrl = _endpointSelector.getMasterLogEndpoint(this.moduleId);

            preLoader.show();
            const response = await axios.post<SyncLogGetResponse>(syncLogMasterUrl, { ...state, ...subParams }, {
                headers: { Authorization: `Bearer ${authService.getToken()}` }
            });
            
            // assign respective company names
            response.data.result.result.forEach(log => {
                log.companyName = this.companies.find(c => c.id == log.companyId)?.name ?? "";
            });

            this.data = response.data.result;
            preLoader.hide();
            
        },
        loadSyncLogDetails(state: any) {
            if (state.action) {
                delete state.action;
            }

            const gridSyncLogDetails:any = this.$refs.gridSyncLogDetails;
            if(gridSyncLogDetails) gridSyncLogDetails.hideSpinner();

            const syncLogMasterId = this.details.syncLogMasterId;
            const moduleId = this.moduleId;
            
            const syncLogDetailsEndpoint = _endpointSelector.getMasterLogDetailsEndpoint(this.moduleId);
            
            preLoader.show();
            axios.post<SyncLogDetailsGetResponse>(syncLogDetailsEndpoint, { ...state, syncLogMasterId, moduleId }, {
                headers: { Authorization: `Bearer ${authService.getToken()}` }
            }).then((response) => {
                if (response) {
                    this.details.data = response.data.result;
                    preLoader.hide()
                }
            }).catch(() => { 
                preLoader.hide();
            });
        },
        onCompanyChange() {
            //this.companyId = companyId;
            this.loadSyncLog(this.initState(this.pageSettings.skip,this.pageSettings.take));
        },
        onSubscriptionChanged() {
            this.moduleId = 0;
            this.companyId = 0;
            this.data = _syncLogData;
            this.loadModule();
        },
        async changeModule() {
            await this.loadCompanies();
            await this.loadSyncLog(this.initState(this.pageSettings.skip,this.pageSettings.take));
        },
        gridLocalize() {
            this.locale = 'en-grid';
            if (this.$i18n.locale == 'se') {
                setTimeout(() => {
                    this.locale = 'se-grid';
                    setCulture('se-grid');
                    L10n.load({
                        ...this.gridLocal
                    });
                });
            }
        },
        open() {
            modal.Open('requestedModal');
        },
        close() {
            this.details.syncLogMasterId = 0;
            modal.Close('requestedModal');
        },
        async loadModule() {
            const { isHost, subscriptionId } = this.userStore.userInfo;
            const subId = isHost ? this.subscriptionId : subscriptionId;

            const response = await axios.get<CommonResponse<Module>>(`${masterServiceBaseURL}v1/Module/GetModules?subscriptionId=${subId}`, {
                headers: { Authorization: `Bearer ${authService.getToken()}` }
            });

            this.moduleList = response.data.result;
        },
        async loadCompanies() {
            const { isHost, subscriptionId } = this.userStore.userInfo;
            const subId = isHost ? this.subscriptionId : subscriptionId;
            
            preLoader.show();
            const response = await axios.get<CommonResponse<Company>>(`${masterServiceBaseURL}v1/Subscription/companies?subscriptionId=${subId}&moduleId=${this.moduleId}`, {
                headers: { Authorization: `Bearer ${authService.getToken()}` }
            });
            
            this.companies = response.data.result;
            preLoader.hide();
        }
    }
});
