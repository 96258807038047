
import { GridComponent as EjsGrid, ColumnsDirective as EColumns, ColumnDirective as EColumn, Resize, Toolbar, CommandColumn, Page, Group, Sort }
    from '@syncfusion/ej2-vue-grids';
import { defineComponent } from 'vue';
import modal from '@/components/common/modalManage';
import staticMessage from '@/components/common/staticMessage';
import agent, { masterServiceBaseURL } from '@/services/agent';
import toasterService from '@/services/toasterService';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import authService from '@/services/authService';
import _ from 'lodash';
import { GetRoleWisePagePermission, Property, Role, RoleGetResponse, RolePageGetResponse, RolePagesResponse } from './Permission.model';
import SubscriptionCommonComponent from '../Subscription/SubscriptionCommonComponent.vue';
import { Subscription } from '../Subscription/Subscription.model';
import { TreeViewComponent  } from "@syncfusion/ej2-vue-navigations";
import { API_KEY } from './constants';
import { DialogUtility } from '@syncfusion/ej2-vue-popups';

let _roleData: Property = {} as Property;
let _subscription = {} as Subscription | undefined;
let Confirmation: any = undefined;


export default defineComponent({
    components: {
        "ejs-grid": EjsGrid,
        "e-columns": EColumns,
        "e-column": EColumn,
        "ejs-treeview": TreeViewComponent, 
        SubscriptionCommonComponent
    },
    provide: {
        grid: [Toolbar, Resize, CommandColumn, Page, Sort, Group]
    },
    created() {
        agent.setBaseUrl(masterServiceBaseURL);

        this.gridLocalize();
    },
    data() {
        return {
            data: _roleData,
            rolePages: [] as GetRoleWisePagePermission[],
            role:{} as Role,
            isUpdate: false,
            isCheckedAll: false,
            selectedState: null,
            subscriptionId: "",
            selectedSubscription: _subscription as Subscription,
            searchOptions: { fields: ['Name', 'DisplayName'], operator: 'contains', ignoreCase: true },
            toolbar: ['Search'],
            pageSettings: {
                pageSizes: [10, 20, 50, 100],
                pageSize: 50,
                pageCount: 5,
                skip:0,
                take:50
            },
            commands: [
                { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
                { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
            ],
            treeManage:{
                isExpand:false,
                isSelect:false,
                treeSource:{
                    fields: { dataSource: [] as GetRoleWisePagePermission[], id: 'id', parentID: 'pid', text: 'name', hasChildren: 'hasChild' },
                },  
            },
            locale: ''
        }
    },
    mounted() {
        this.dataStateChange(this.initState(this.pageSettings.skip,this.pageSettings.take));
    },
    methods: {
        treeView(){
            const treeView:any = document.getElementById('treeviewPage')!;
            return treeView.ej2_instances[0];
        },
        initState(skip:number=0,take:number): any {
            let state = {
                skip: skip,
                take: take
            };
            return state;
        },
        dataStateChange(state: any) {
            this.pageSettings.skip = state.skip;    
            this.pageSettings.take = state.take;
            this.gridLocalize();
            this.loadRole(state);
        },
        async loadRole(state: any) {
            if (state.action) {
                delete state.action;
            }

            var subscriptionId = this.subscriptionId;
            const gridRole:any = this.$refs.gridRole;
            if(gridRole) gridRole.hideSpinner();

            const response = await agent.post<RoleGetResponse>(API_KEY.GET_ROLE, { ...state, subscriptionId });
            if (response && response.result) {
                this.data = response.result;
            }
        },
        onUpsert() {
            if (this.isUpdate) {
                this.onEdit();
            } else {
                this.onSave();
            }
        },
        checkingPages(){
            if(this.treeView()){
                const checkedAll = this.treeView().getAllCheckedNodes();
                _.map(this.rolePages, item => {
                    item.isChecked = checkedAll.some((id:any) => Number(id) == item.id);
                });
            }
        },
        async onSave() {
            this.checkingPages();

            this.role.name = this.role.displayName;
            const post = {
                role: this.role,
                rolePages: this.rolePages,
                createdOrModifiedBy: authService.getUserEmail(),
                subscriptionId: this.selectedSubscription?.id
            };

            if (_.isEmpty(post.role.name)) {
                toasterService.warn(this.$t('msgGiveRole'));
                return;
            }

            if (!post.rolePages.some(i => i.isChecked == true)) {
                toasterService.warn(this.$t('msgSelectOnePageAtLeast'));
                return;
            }

            const response = await agent.post<RolePagesResponse>(API_KEY.POST, post);

            if (response && response.result) {
                this.loadRole(this.initState(this.pageSettings.skip,this.pageSettings.take));
                toasterService.success(this.$t("msgSave"));
                this.close();
                this.$router.go(0);
            }
        },
        async onEdit() {
            this.checkingPages();

            this.role.name = this.role.displayName;
            const put = {
                role: this.role,
                rolePages: this.rolePages,
                createdOrModifiedBy: authService.getUserEmail()
            };

            if (_.isEmpty(put.role)) {
                toasterService.warn(this.$t('msgGiveRole'));
                return;
            }

            if (!put.rolePages.some(i => i.isChecked == true)) {
                toasterService.warn(this.$t('msgSelectOnePageAtLeast'));
                return;
            }

            const response = await agent.put<RolePagesResponse>(API_KEY.PUT, put);
            if (response && response.result) {
                this.loadRole(this.initState(this.pageSettings.skip,this.pageSettings.take));
                toasterService.success(this.$t("msgUpdate"));
                this.close();
                this.$router.go(0);
            }
        },
        async onDelete(id:number) {
            const response = await agent.delete<RolePagesResponse>(API_KEY.DELETE_ROLE_PAGE(id));
            if (response && response.result) {
                this.loadRole(this.initState(this.pageSettings.skip,this.pageSettings.take));
                toasterService.success(staticMessage.delete);
                modal.Close(staticMessage.modalConfirm);
                this.close();
            }
        },
        async loadRolePage(id: number,subscriberType?:string,subscriptionId?:string) {
            const payload = {
                roleId: id,
                subscriberType: subscriberType,
                subscriptionId:subscriptionId
            }
            const response = await agent.get<RolePageGetResponse>(API_KEY.GET,payload);

            if (response && response.result) {
                this.rolePages = response.result;
                this.treeManage.isExpand = false;
                this.treeManage.isSelect = false;
                this.treeManage.treeSource.fields = { dataSource: this.rolePages, id: 'id', parentID: 'pid', text: 'name', hasChildren: 'hasChild' }
                modal.Open('roleModal');
            }
        },
        onExpandCollapseAll(){
            this.treeManage.isExpand = !this.treeManage.isExpand;
            if (this.treeView()) {
                if (this.treeManage.isExpand) {
                    this.treeView().expandAll();
                } else {
                    this.treeView().collapseAll();
                }
            }
        },
        onSelectUnselectAll(){
            this.treeManage.isSelect = !this.treeManage.isSelect;
            if (this.treeView()) {
                if (this.treeManage.isSelect) {
                    this.treeView().checkAll();
                } else {
                    this.treeView().uncheckAll();
                }
            }
        },
        onSubscriptionChange(subscriptionId: string) {
            this.subscriptionId = subscriptionId;
            if (subscriptionId.trim().toLowerCase().indexOf("all") > -1) {
                this.subscriptionId = "";
            }

            this.loadRole(this.initState(this.pageSettings.skip,this.pageSettings.take));

            const subscriptionComponent: any = this.$refs.subscriptionComponentRef;
            const subscription = (subscriptionComponent.data as Subscription[]).find(s => s.id === subscriptionId) as Subscription;
            this.selectedSubscription = subscription;
        },
        resetRole() {
            this.role = {} as Role;
            this.rolePages = [];
            this.isUpdate = false;
        },
        gridLocalize() {
            this.locale = 'en-grid';
            if (this.$i18n.locale == 'se') {
                setTimeout(() => {
                    import(`@/assets/sv.json`).then(module => {
                        const localText = module.default;
                        this.locale = 'sv';
                        setCulture('sv');
                        L10n.load(localText);
                    });
                });
            }
        },
        commandClick: function (args: { commandColumn: any, rowData: Role }) {
            this.isUpdate = false;
            const app = this;
            if (args) {
                switch (args.commandColumn.type) {
                    case 'Delete':
                        this.role = args.rowData;
                        Confirmation = DialogUtility.confirm({
                            title: this.$t("deleteConfiramtion"),
                            content: this.$t("msgSureWantToDelete"),
                            okButton: {
                            text: this.$t("ok"),
                            click: async function () {
                                Confirmation.hide();
                                app.onDelete(app.role.id);
                            },
                            },
                            cancelButton: { text: this.$t("cancel") },
                            showCloseIcon: true,
                            closeOnEscape: true,
                            zIndex: 10000,
                            animationSettings: { effect: "Zoom" },
                        });
                        break;
                    case 'Edit':
                        this.role = args.rowData;
                        this.isUpdate = true;
                        this.loadRolePage(this.role.id,this.role.subscriberType,this.role.subscriptionId);
                        break;
                    default: break;
                }
            }
        },
        resetRolePage() {
            _.map(this.rolePages, item => {
                item.isChecked = false;
            });
            this.role = {} as Role;
            this.isUpdate = false;
            this.isCheckedAll = false;
            this.role.organizationName = String(this.selectedSubscription?.organizationName);
        },
        isHost() {
            return authService.isHost();
        },
        open() {
            this.resetRolePage();
            this.loadRolePage(0,this.selectedSubscription.subscriberType,this.selectedSubscription.id);
        },
        close() {
            this.resetRolePage();
            modal.Close('roleModal');
        },
    }
});
