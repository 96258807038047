import { fortnoxServiceBaseURL, masterServiceBaseURL } from '@/services/agent';

export default class EndpointResolver {
    constructor(){}
    
    getMasterLogDetailsEndpoint(moduleId: number): string {
        let endpoint = '';

        if (moduleId == 2) { // Fortnox
            endpoint = `${fortnoxServiceBaseURL}v1/SyncLogDetails/GetSyncLogDetails`;
        }

        return endpoint;
    }

    getMasterLogEndpoint(moduleId: number): string {
        let endpoint = '';

        if (moduleId == 2) { // Fortnox
            endpoint = `${fortnoxServiceBaseURL}v1/SyncLogMaster/GetSyncLogMaster`;
        }

        return endpoint;
    }
}