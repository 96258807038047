
import { 
    GridComponent as EjsGrid, 
    ColumnsDirective as EColumns, 
    ColumnDirective as EColumn, 
    Resize, 
    Toolbar, 
    CommandColumn, 
    Page, 
    Group, 
    Sort 
} from '@syncfusion/ej2-vue-grids';
import { DatePickerComponent as EjsDatePicker } from '@syncfusion/ej2-vue-calendars';
import { defineComponent } from 'vue';
import agent, { masterServiceBaseURL } from '@/services/agent';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { FinancialYear, FinancialYearGetResponse, Property } from './FinancialYear.model';
import CompanyCommonComponent from '../../../fortnox/common/CompanyCommonComponent.vue';
import authService from '@/services/authService';
import modal from '@/components/common/modalManage';
import staticMessage from '@/components/common/staticMessage';
import toasterService from '@/services/toasterService';
import axios from 'axios';
import preLoader from '@/services/preLoader';
import ConfirmComponent from '@/components/molecules/ConfirmComponent.vue';
import SubscriptionCommonComponent from '../Subscription/SubscriptionCommonComponent.vue';
import _ from 'lodash';
import { hasFortnox } from '@/shared/app.util';
import VueSelect from '@/components/common/VueSelect.vue';
import { Company } from '../Company/Company.model';

let _financialYearData: Property = {} as Property;
let _financialYearForm: FinancialYear = {} as FinancialYear;
let _companyList = [] as Company[]

export default defineComponent({
    components: {
        "ejs-grid": EjsGrid,
        "e-columns": EColumns,
        "e-column": EColumn,
        CompanyCommonComponent,
        ConfirmComponent,
        SubscriptionCommonComponent,
        EjsDatePicker,
        VueSelect
    },
    provide: {
        grid: [Toolbar, Resize, CommandColumn, Page, Sort, Group]
    },
    created() {
        agent.setBaseUrl(masterServiceBaseURL);

        this.gridLocalize();
    },
    data() {
        return {
            data: _financialYearData,
            form: _financialYearForm,
            isUpdate: false,
            subscriptionId: "",
            isTableLoad: false,
            confirmTitle: "",
            confirmIsDelete: false,
            selectedState: null,
            companyId: 0,
            companies: _companyList,
            searchOptions: { fields: ['AccountCharts', 'AccountingMethod', 'Id', 'FromDate', 'ToDate'], operator: 'contains', ignoreCase: true },
            toolbar: ['Search'],
            pageSettings: {
                pageSizes: [10, 20, 50, 100],
                pageSize: 50,
                pageCount: 5
            },
            commands: [
                { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
                { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
            ],
            locale: '',
            gridLocal: {
                'se-grid': {
                    'grid': {
                        'EmptyRecord': 'Inga poster visas', 'Search': 'Sök',
                        'GroupDropArea': 'Dra en kolumnrubrik hit för att gruppera din kolumn',
                        'UnGroup': 'Klicka här för att avgruppera',
                        'EmptyDataPageError': 'DataPage får inte vara tom vid den första laddningen eftersom kolumner från dataPage finns i AutoGenerate Column Grid',
                        'Item': 'Artikel',
                        'Items': 'Artikel'
                    },
                    'pager': {
                        'currentPageInfo': '{0} av {1} sidor',
                        'totalItemsInfo': '({0} inlägg)',
                        'firstPageTooltip': 'Till första sidan',
                        'lastPageTooltip': 'Till sista sidan',
                        'nextPageTooltip': 'Till nästa sida',
                        'previousPageTooltip': 'Tillbaka till sista sidan',
                        'nextPagerTooltip': 'Till nästa personsökare',
                        'previousPagerTooltip': 'Gå till föregående personsökare',
                        'pagerDropDown': 'Objekt per sida'
                    }
                }
            }
        }
    },
    mounted() {
        this.dataStateChange(this.initState());
        this.loadCompany();
    },
    methods: {
        initState(): any {
            let state = {
                skip: 0,
                take: this.pageSettings.pageSize
            };
            return state;
        },
        dataStateChange(state: any) {
            this.gridLocalize();
            this.loadFinancialYear(state);
        },
        loadFinancialYear(state: any) {
            if (state.action) {
                delete state.action;
            }

            const gridFinancialYear:any = this.$refs.gridFinancialYear;
            if(gridFinancialYear) gridFinancialYear.hideSpinner();

            const companyId = this.companyId;
            let urlBase: string = authService.getModulesBaseUrl()!.fortnox;

            if (hasFortnox()) {
                preLoader.show();
                axios.post(`${urlBase}v1/financialYear/getfinancialYear`, { ...state, companyId }, {
                    headers: { Authorization: `Bearer ${authService.getToken()}` }
                }).then((response) => {
                    if (response) {
                        preLoader.hide();
                        let financialYears: FinancialYear[] = [];
                        _.map(response.data.result.result, item => {
                            let financialYear: FinancialYear = {
                                id: item.id,
                                accountCharts: item.accountCharts,
                                accountingMethod: item.accountingMethod,
                                companyName: item.companyName,
                                companyId: item.companyId,
                                fromDate: item.fromDate,
                                toDate: item.toDate,
                                createdBy: '',
                                modifiedBy: ''
                            };
                            financialYears.push(financialYear);
                        });
                        this.data = { result: financialYears, count: financialYears.length };
                    }
                }).catch(() => { });
            } else {
                console.log('cid: ', this.companyId);
                const subscriptionId = this.subscriptionId;
                agent.post<FinancialYearGetResponse>(`v1/financialYear/getfinancialYear`, 
                    { ...state, subscriptionId, companyId })
                .then((response) => {
                    if (response.result) {
                        this.data = response.result;
                    }
                }).catch(() => { });
            }
        },
        loadCompany() {
            axios.post(`${masterServiceBaseURL}v1/company/getcompany`, {}, {
                headers: { Authorization: `Bearer ${authService.getToken()}` }
            }).then((response) => {
                if (response) {
                    this.companies = response.data.result.result;
                }
            }).catch(() => { });
        },
        commandClick: function (args: { commandColumn: any, rowData: FinancialYear }) {
            this.isUpdate = false;
            if (args) {
                switch (args.commandColumn.type) {
                    case 'Delete':
                        this.confirmTitle = this.$t("msgSureWantToDelete");
                        this.form.id = args.rowData.id;
                        this.confirmIsDelete = true;
                        modal.Open(staticMessage.modalConfirm);
                        break;
                    case 'Edit':
                        this.form = args.rowData;
                        this.isUpdate = true;
                        modal.Open('financialyearModal');
                        break;
                    default: break;
                }
            }
        },
        open() {
            this.form = { companyId: 0 } as FinancialYear;
            this.isUpdate = false;
            modal.Open('financialyearModal');
        },
        close() {
            this.form = {} as FinancialYear;
            // this.form.fromDate = null;
            // this.form.toDate = null;
            
            (this.$refs.datepicker_from_date as any)?.ej2Instances?.clear();
            (this.$refs.datepicker_to_date as any)?.ej2Instances?.clear();

            modal.Close('financialyearModal');
        },
        subscriptionChange(subscriptionId: string) {
            this.subscriptionId = subscriptionId;
            if (subscriptionId.trim().toLowerCase().indexOf("all") > -1) {
                this.subscriptionId = "";
            }

            this.loadFinancialYear(this.initState());
        },
        onUpsert() {
            console.log('dates: ', this.form.fromDate);

            if (this.isUpdate) {
                this.onEdit();
            } else {
                this.onSave();
            }
        },
        onSave() {
            let post = { ...this.form };
            post.createdBy = authService.getUserEmail();

            agent.post<FinancialYearGetResponse>(`v1/financialyear`, post).then((response) => {
                if (response.result) {
                    toasterService.success(this.$t("msgSave"));
                    this.loadFinancialYear(this.initState());
                    this.resetFinancialYear();
                    this.close();
                }
            }).catch(() => { });
        },
        onEdit() {
            let put = { ...this.form };
            put.modifiedBy = authService.getUserEmail();

            agent.put<FinancialYearGetResponse>(`v1/financialyear`, put).then((response) => {
                if (response.result) {
                    toasterService.success(this.$t("msgUpdate"));
                    this.loadFinancialYear(this.initState());
                    this.resetFinancialYear();
                    this.close();
                }
            }).catch(() => { });
        },
        onDelete() {
            let put = { ...this.form };

            agent.delete<FinancialYearGetResponse>(`v1/financialyear/${put.id}`).then((response) => {
                modal.Close(staticMessage.modalConfirm);
                if (response.result) {
                    toasterService.success(staticMessage.delete);
                    this.loadFinancialYear(this.initState());
                    this.resetFinancialYear();
                }
            }).catch(() => { });
        },
        resetFinancialYear() {
            this.form = {} as FinancialYear;
            this.isUpdate = false;
        },
        commandConfirm() {
            if (this.confirmIsDelete) {
                this.onDelete();
                this.confirmIsDelete = false;
            }
        },
        onCompanyChange(companyId: number) {
            this.companyId = companyId;
            this.loadFinancialYear(this.initState());
        },
        isFortnox() {
            return hasFortnox();
        },
        isHost() {
            return authService.isHost();
        },
        gridLocalize() {
            this.locale = 'en-grid';
            if (this.$i18n.locale == 'se') {
                setTimeout(() => {
                    this.locale = 'se-grid';
                    setCulture('se-grid');
                    L10n.load({
                        ...this.gridLocal
                    });
                });
            }
        }
    }
});
