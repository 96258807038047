import { RouteRecordRaw } from 'vue-router';

const fortnoxPageRoutes: RouteRecordRaw[] = [
    {
        path: "/customers",
        name: "Customers",
        component: () => import("@/components/fortnox/features/Customers/CustomersComponent.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/account",
        name: "Fortnox Account",
        component: () => import("@/components/fortnox/features/Account/AccountComponent.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/accountChart",
        name: "Account Chart",
        component: () => import("@/components/fortnox/features/AccountChart/AccountChartComponent.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/project",
        name: "Fortnox Project",
        component: () => import("@/components/fortnox/features/Project/ProjectComponent.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/companysettings",
        name: "Company Settings",
        component: () => import("@/components/fortnox/features/CompanySettings/CompanySettingsComponent.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/invoice",
        name: "Invoice",
        component: () => import("@/components/fortnox/features/Invoice/InvoiceComponent.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/invoiceAccural",
        name: "Invoice Accural",
        component: () => import("@/components/fortnox/features/InvoiceAccural/InvoiceAccrualComponent.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/invoicePayment",
        name: "Invoice Payment",
        component: () => import("@/components/fortnox/features/InvoicePayment/InvoicePaymentComponent.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: '/absence-transaction',
        name: 'AbsenceTransaction',
        component: () => import("@/components/fortnox/features/AbsenceTransaction/AbsenceTransactionComponent.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/article-file-connection',
        name: 'ArticleFileConnection',
        component: () => import("@/components/fortnox/features/ArticleFileConnection/ArticleFileConnectionComponent.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/asset',
        name: 'Asset',
        component: () => import("@/components/fortnox/features/Asset/AssetComponent.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/asset-file-connection',
        name: 'AssetFileConnection',
        component: () => import("@/components/fortnox/features/AssetFileConnection/AssetFileConnectionComponent.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/asset-type',
        name: 'AssetType',
        component: () => import("@/components/fortnox/features/AssetType/AssetTypeComponent.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/attendance-transaction',
        name: 'AttendanceTransaction',
        component: () => import("@/components/fortnox/features/AttendanceTransaction/AttendanceTransactionComponent.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/contract',
        name: 'Contract',
        component: () => import("@/components/fortnox/features/Contract/ContractComponent.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/contract-accrual',
        name: 'ContractAccrual',
        component: () => import("@/components/fortnox/features/ContractAccrual/ContractAccrualComponent.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/contract-template',
        name: 'ContractTemplate',
        component: () => import("@/components/fortnox/features/ContractTemplate/ContractTemplateComponent.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/currency',
        name: 'Currency',
        component: () => import("@/components/fortnox/features/Currency/CurrencyComponent.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/expense',
        name: 'Expense',
        component: () => import("@/components/fortnox/features/Expense/ExpenseComponent.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/locked-period',
        name: 'LockedPeriod',
        component: () => import("@/components/fortnox/features/LockedPeriod/LockedPeriodComponent.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/offer',
        name: 'Offer',
        component: () => import("@/components/fortnox/features/Offer/OfferComponent.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/auth-setting",
        name: "Auth Setting",
        component: () => import("@/components/fortnox/features/AuthSetting/AuthSettingComponent.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/suppliers",
        name: "Suppliers",
        component: () => import("@/components/fortnox/features/suppliers/Suppliers.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/units",
        name: "Units",
        component: () => import("@/components/fortnox/features/unit/Unit.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/supplier-invoice-payment",
        name: "SupplierInvoicePayment",
        component: () => import("@/components/fortnox/features/SupplierInvoicePayment/SupplierInvoicePayment.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/supplier-invoice-accrual",
        name: "SupplierInvoiceAccrual",
        component: () => import("@/components/fortnox/features/SupplierInvoiceAccrual/SupplierInvoiceAccrual.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/salary-transaction",
        name: "SalaryTransaction",
        component: () => import("@/components/fortnox/features/SalaryTransaction/SalaryTransaction.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/price-list",
        name: "PriceList",
        component: () => import("@/components/fortnox/features/PriceList/PriceList.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/order",
        name: "Order",
        component: () => import("@/components/fortnox/features/Order/Order.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/employee",
        name: "Employee",
        component: () => import("@/components/fortnox/features/Employee/Employee.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/prices",
        name: "Prices",
        component: () => import("@/components/fortnox/features/Prices/Prices.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/schedule-time",
        name: "ScheduleTime",
        component: () => import("@/components/fortnox/features/ScheduleTime/ScheduleTime.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/supplier-invoice-external-url-connection",
        name: "SupplierInvoiceExternalUrlConnection",
        component: () => import("@/components/fortnox/features/SupplierInvoiceExternalUrlConnection/SupplierInvoiceExternalUrlConnection.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/supplierInvoice",
        name: "Supplier Invoice",
        component: () => import("@/components/fortnox/features/SupplierInvoice/SupplierInvoiceComponent.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/supplierInvoiceFileConnection",
        name: "Supplier Invoice File Connection",
        component: () => import("@/components/fortnox/features/SupplierInvoiceFileConnection/SupplierInvoiceFileConnectionComponent.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/voucherFileConnection",
        name: "Voucher File Connection",
        component: () => import("@/components/fortnox/features/VoucherFileConnection/VoucherFileConnectionComponent.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/voucher",
        name: "Voucher",
        component: () => import("@/components/fortnox/features/Voucher/VoucherComponent.vue"),
        meta: { requiresAuth: true },
    },
    {
		path: "/cost-center",
		name: "Fortnox Cost Center",
		component: () => import("@/components/fortnox/features/CostCenter/CostCenterComponent.vue"),
		meta: { requiresAuth: true },
	},
];

export default fortnoxPageRoutes;