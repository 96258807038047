
import { GridComponent as EjsGrid, ColumnsDirective as EColumns, ColumnDirective as EColumn, Resize, Toolbar, CommandColumn, Page, Group, Sort, Freeze }
    from '@syncfusion/ej2-vue-grids';
import { defineComponent } from 'vue';
import modal from '@/components/common/modalManage';
import staticMessage from '@/components/common/staticMessage';
import agent, { masterServiceBaseURL } from '@/services/agent';
import toasterService from '@/services/toasterService';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { PageForm, PageFormGetResponse, PageFormResponse, Property } from './Page.model';
import authService from '@/services/authService';
import { Module, ModuleGetResponse } from '../Module/Module.model';
import { Subscription } from '../Subscription/Subscription.model';
import { SubscriptionGetResponse } from '../User/User.model';
import SubsriberType from '../../common/SubsriberTypeEnum';
import ModuleEnum from '../../common/ModuleEnum';
import { DropDownListComponent } from '@syncfusion/ej2-vue-dropdowns';
import { DialogUtility } from '@syncfusion/ej2-vue-popups';
import { API_KEY } from './constants';


let _pageData: Property = {} as Property;
let _pageForm: PageForm = {} as PageForm;
let _parentPage: PageForm[] = [];
let _modules: Module[] = [];
let _subscriptions: Subscription[] = [];
let Confirmation: any = undefined;

export default defineComponent({
    components: {
        "ejs-grid": EjsGrid,
        "e-columns": EColumns,
        "e-column": EColumn,
        "ejs-dropdownlist": DropDownListComponent
    },
    provide: {
        grid: [Toolbar, Resize, CommandColumn, Page, Sort, Group, Freeze]
    },
    created() {
        agent.setBaseUrl(masterServiceBaseURL);

        this.gridLocalize();
    },
    data() {
        return {
            listSubscription: _subscriptions,
            data: _pageData,
            form: _pageForm,
            isUpdate: false,
            selectedState: null,
            searchOptions: { fields: ['Title', 'Url', 'Icon'], operator: 'contains', ignoreCase: true },
            toolbar: ['Search'],
            pageSettings: {
                pageSizes: [10, 20, 50, 100],
                pageSize: 50,
                pageCount: 5,
                skip:0,
                take:50
            },
            commands: [
                { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
                { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
            ],
            module:{
                data:_modules,
                fields:{ text: 'name', value: 'id' }
            },
            parentPage:{
                data:_parentPage,
                fields:{ text: 'title', value: 'id' }
            },
            subscriberType:{
                data:[SubsriberType.Host,SubsriberType.Tenant],
                fields:{ text: 'name', value: 'name' }
            },
            locale: ''
        }
    },
    mounted() {
        this.dataStateChange(this.initState(this.pageSettings.skip,this.pageSettings.take));
        this.loadModule();
        this.loadSubscription();
    },
    methods: {
        initState(skip:number=0,take:number): any {
            let state = {
                skip: skip,
                take: take
            };
            return state;
        },
        dataStateChange(state: any) {
            this.pageSettings.skip = state.skip;    
            this.pageSettings.take = state.take;
            this.gridLocalize();
            this.loadPage(state);
        },
        chkIsParentChange(){
            if(this.form.isParent){
                this.form.parentId = null;
            }
        },
        async loadPage(state: any) {
            if (state.action) {
                delete state.action;
            }

            const gridPage:any = this.$refs.gridPage;
            if(gridPage) gridPage.hideSpinner();

            const response = await agent.post<PageFormGetResponse>(API_KEY.GET_PAGES, state);
            if(response && response.result){
                this.data = response.result;
                if (this.parentPage.data.length == 0) {
                    this.parentPage.data = this.data.result.filter(x => x.isParent === true)
                }
            }
        },
        async loadModule() {
            const response = await agent.post<ModuleGetResponse>(API_KEY.GET_MODULES, {});
            if (response && response.result) {
                this.module.data = [{
                        id:0,
                        name:this.$t('root')
                    } as Module,...response.result.result];
            }
        },
        async loadSubscription() {
            const response = await agent.post<SubscriptionGetResponse>(API_KEY.GET_SUBSCRIPTIONS, {});
            if (response && response.result) {
                this.listSubscription = response.result.result;
            }
        },
        onUpsert(event: any) {
            event.preventDefault();

            if (this.isUpdate) {
                this.onEdit();
            } else {
                this.onSave();
            }
        },
        async onSave() {
            let post = { ...this.form };
            post.createdBy = authService.getUserEmail();

            if (post.isParent) post.parentId = undefined;

            post.subscriberType = String(post.subscriberType);

            const response = await agent.post<PageFormResponse>(API_KEY.POST, post);
            if (response && response.result) {
                toasterService.success(staticMessage.save);
                this.loadPage(this.initState(this.pageSettings.skip,this.pageSettings.take));
                this.resetPage();
                this.close();
            }
        },
        async onEdit() {
            let put = { ...this.form };

            put.modifiedBy = authService.getUserEmail();

            if (put.isParent) put.parentId = undefined;

            put.subscriberType = String(put.subscriberType);

            const response = await agent.put<PageFormResponse>(API_KEY.PUT, put);
            if (response && response.result) {
                toasterService.success(staticMessage.update);
                this.loadPage(this.initState(this.pageSettings.skip,this.pageSettings.take));
                this.resetPage();
                this.close();
            }
        },
        async onDelete(id:number) {
            const response = await agent.delete<PageFormResponse>(`v1/page/${id}`);
            if (response && response.result) {
                toasterService.success(staticMessage.delete);
                this.loadPage(this.initState(this.pageSettings.skip,this.pageSettings.take));
                this.resetPage();
            }
        },
        resetPage() {
            this.form = {} as PageForm;
            this.form.parentId = 0;
            this.form.moduleId = 0;
            this.isUpdate = false;
            this.form.subscriberType = SubsriberType.Host.name;
        },
        gridLocalize() {
            this.locale = 'en-grid';
            if (this.$i18n.locale == 'se') {
                setTimeout(() => {
                    import(`@/assets/sv.json`).then(module => {
                        const localText = module.default;
                        this.locale = 'sv';
                        setCulture('sv');
                        L10n.load(localText);
                    });
                });
            }
        },
        commandClick: function (args: { commandColumn: any, rowData: any }) {
            this.isUpdate = false;
            const app = this;
            if (args) {
                switch (args.commandColumn.type) {
                    case 'Delete':
                        this.form.id = args.rowData.id;
                        Confirmation = DialogUtility.confirm({
                            title: this.$t("deleteConfiramtion"),
                            content: this.$t("msgSureWantToDelete"),
                            okButton: {
                            text: this.$t("ok"),
                            click: async function () {
                                Confirmation.hide();
                                app.onDelete(app.form.id);
                            },
                            },
                            cancelButton: { text: this.$t("cancel") },
                            showCloseIcon: true,
                            closeOnEscape: true,
                            zIndex: 10000,
                            animationSettings: { effect: "Zoom" },
                        });
                        break;
                    case 'Edit':
                        this.form = args.rowData;
                        switch (String(args.rowData.module).toLowerCase()) {
                            case ModuleEnum.ResultSheet.name.toLocaleLowerCase():
                                this.form.moduleId = ModuleEnum.ResultSheet.id;
                                break;
                            case ModuleEnum.Fortnox.name.toLocaleLowerCase():
                                this.form.moduleId = ModuleEnum.Fortnox.id;
                                break;
                            case ModuleEnum.PageBuilder.name.toLocaleLowerCase():
                                this.form.moduleId = ModuleEnum.PageBuilder.id;
                                break;
                            default:
                                this.form.moduleId = 0;
                                break;
                        }
                        this.isUpdate = true;
                        modal.Open('PageModal');
                        break;
                    default: break;
                }
            }
        },
        open() {
            this.resetPage();
            modal.Open('PageModal');
        },
        close() {
            this.form = {} as PageForm;
            modal.Close('PageModal');
        },
    }
});
