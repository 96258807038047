
import { GridComponent as EjsGrid, ColumnsDirective as EColumns, ColumnDirective as EColumn, Resize, Toolbar, CommandColumn, Page, Group, Sort }
    from '@syncfusion/ej2-vue-grids';
import { defineComponent } from 'vue';
import modal from '@/components/common/modalManage';
import staticMessage from '@/components/common/staticMessage';
import ConfirmComponent from '@/components/molecules/ConfirmComponent.vue';
import agent, { masterServiceBaseURL } from '@/services/agent';
import toasterService from '@/services/toasterService';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { Subscription, SubscriptionGetResponse, SubscriptionResponse, Property } from './Subscription.model';
import RegisterModalComponent from '../Register/RegisterModalComponent.vue';
import SubsriberType from '../../common/SubsriberTypeEnum';
import { ChipListComponent } from '@syncfusion/ej2-vue-buttons';
import emitter from '@/services/mitt';

let _subscriptionData: Property = {} as Property;
let _subscriptionForm: Subscription = {} as Subscription;

export default defineComponent({
    components: {
        "ejs-grid": EjsGrid,
        "e-columns": EColumns,
        "e-column": EColumn,
        'ejs-chiplist': ChipListComponent,
        ConfirmComponent,
        RegisterModalComponent
    },
    provide: {
        grid: [Toolbar, Resize, CommandColumn, Page, Sort, Group]
    },
    created() {
        agent.setBaseUrl(masterServiceBaseURL);
        this.gridLocalize();
    },
    data() {
        return {
            data: _subscriptionData,
            form: _subscriptionForm,
            isUpdate: false,
            confirmTitle: "",
            confirmIsDelete: false,
            selectedState: null,
            isLoading: false,
            showRegisterModal: false,
            organizationName:"",
            searchOptions: { fields: ['OrganizationName'], operator: 'contains', ignoreCase: true },
            toolbar: ['Search'],
            pageSettings: {
                pageSizes: [10, 20, 50, 100],
                pageSize: 50,
                pageCount: 5,
                skip:0,
                take:50
            },
            commands: [
                { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
                { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
            ],
            locale: ''
        }
    },
    mounted() {
        this.isLoading = true;
        this.dataStateChange(this.initState(this.pageSettings.skip,this.pageSettings.take));
        emitter.on('changeSubscriptions', () => {
            this.changeEffect();
        });
    },
    methods: {
        initState(skip:number=0,take:number): any {
            let state = {
                skip: skip,
                take: take
            };
            return state;
        },
        dataStateChange(state: any) {
            this.gridLocalize();
            this.loadSubscription(state);
        },
        loadSubscription(state: any) {
            if (state.action) {
                delete state.action;
            }

            const gridsubscription:any = this.$refs.gridsubscription;
            if(gridsubscription) gridsubscription.hideSpinner();


            agent.post<SubscriptionGetResponse>(`v1/subscription/getsubscription`, state).then((response) => {
                if (response.result) {
                    this.data = response.result;
                    this.data.result.map(i => {
                        if (parseInt(i.subscriberType) == 0) {
                            i.subscriberType = SubsriberType.Host.name;
                        } else {
                            i.subscriberType = SubsriberType.Tenant.name;
                        }
                    });
                }
            }).catch(() => { });
        },
        onDelete(isSubsDelete: boolean) {
            let put = {
                id: this.form.id,
                isSubsDelete: isSubsDelete
            };

            modal.Close(staticMessage.modalConfirm);
            agent.delete<SubscriptionResponse>(`v1/subscription`, put).then((response) => {
                if (response.result) {
                    toasterService.success(staticMessage.delete);
                    this.loadSubscription(this.initState(this.pageSettings.skip,this.pageSettings.take));
                    this.resetSubscription();
                }
            }).catch(() => { });
        },
        changeEffect() {
            if (this.isUpdate) toasterService.success(this.$t("msgUpdate"));
            else toasterService.success(this.$t("msgSave"));

            this.loadSubscription(this.initState(this.pageSettings.skip,this.pageSettings.take));
            this.resetSubscription();
            this.close();
        },
        resetSubscription() {
            this.form = {} as Subscription;
            this.isUpdate = false;
        },
        commandConfirm(data: any) {
            if (this.confirmIsDelete) {
                this.onDelete(data.isSubsDelete);
                this.confirmIsDelete = false;
            }
        },
        commandClick: function (args: { commandColumn: any, rowData: Subscription }) {
            this.isUpdate = true;
            if (args) {
                switch (args.commandColumn.type) {
                    case 'Edit':
                        this.form = args.rowData;
                        this.showRegisterModal = true;
                        emitter.emit('setForm', this.form);
                        modal.Open('subscriptionModal');
                        break;
                    case 'Delete':
                        if (String(args.rowData.subscriberType).toLowerCase() == SubsriberType.Host.name.toLowerCase()) {
                            toasterService.warn(this.$t("Unable to delete host!"));
                            return;
                        }
                        this.confirmTitle = `Are you sure want to delete?`;
                        this.form.id = args.rowData.id;
                        this.confirmIsDelete = true;
                        modal.Open(staticMessage.modalConfirm);
                        break;
                    default: break;
                }
            }
        },
        gridLocalize() {
            this.locale = 'en-grid';
            if (this.$i18n.locale == 'se') {
                setTimeout(() => {
                    import(`@/assets/sv.json`).then(module => {
                        const localText = module.default;
                        this.locale = 'sv';
                        setCulture('sv');
                        L10n.load(localText);
                    });
                });
            }
        },
        open() {
            this.form = {} as Subscription;
            this.isUpdate = false;
            this.showRegisterModal = true;
            document.getElementById('subsWiseModulesId')!.dispatchEvent(new Event('change'));
            modal.Open('subscriptionModal');
        },
        close() {
            this.form = {} as Subscription;
            modal.Close('subscriptionModal');
        },
    }
});
