import localStore from "@/services/localStore";
import { AppConstants } from "./appConstants";
import { LoggedInUser } from "@/components/master/features/User/User.model";
import _ from "lodash";
import moment from "moment";

export const hasFortnox = ():boolean => {
    const user: LoggedInUser = {...localStore.getItem(AppConstants.LOCAL_STORAGE_KEY_USER)};
    return user?.modules.some(m => m.name == AppConstants.Modules.Fortnox);
}

export const getModuleId = (moduleName: string ) => {
    const user: LoggedInUser = {...localStore.getItem(AppConstants.LOCAL_STORAGE_KEY_USER)};
    const module = user?.modules.find(m => m.name == moduleName);
    return module?.id;
}

export const toPascalCase = (str: string) => {
    if(_.isEmpty(str)) return str;
    return str
    .replace(/(\w)(\w*)/g, (_, firstChar, rest) => firstChar.toUpperCase() + rest.toLowerCase())
    .replace(/[^a-zA-Z0-9]/g, '');
}

export const getDateFormat = (date: Date,format:string) => {
    return moment(date).isValid()? moment(date).format(format):"";
}
